"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/web.timers.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _emptyFunction = _interopRequireDefault(require("fbjs/lib/emptyFunction"));

// import
// definition
var _default = function _default(_ref) {
  var width = _ref.width,
      height = _ref.height;

  var _useState = (0, _react.useState)(height),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      currentHeight = _useState2[0],
      setCurrentHeight = _useState2[1];

  var iframeRef = (0, _react.useRef)(null);
  var resizeTimeoutRef = (0, _react.useRef)(setTimeout(_emptyFunction["default"], 0));
  var isMountedRef = (0, _react.useRef)(false);
  (0, _react.useEffect)(function () {
    var resize = function resize() {
      clearTimeout(resizeTimeoutRef.current);
      resizeTimeoutRef.current = setTimeout(function () {
        if (!iframeRef.current) return;
        var _iframeRef$current = iframeRef.current,
            offsetWidth = _iframeRef$current.offsetWidth,
            offsetHeight = _iframeRef$current.offsetHeight;
        if (width === offsetWidth && height === offsetHeight) return;
        setCurrentHeight(height * offsetWidth / width);
      }, 100);
    };

    if (!isMountedRef.current) resize();
    window.addEventListener('resize', resize);
    isMountedRef.current = true;
    return function () {
      clearTimeout(resizeTimeoutRef.current);
      window.removeEventListener('resize', resize);
    };
  }, [width, height, currentHeight]);
  return {
    currentHeight: currentHeight,
    iframeRef: iframeRef
  };
};

exports["default"] = _default;